import React, { useRef, useState } from 'react';
import styles from './DocVerification.module.scss';
import { t } from "i18next";

const DocCapture = ({ setAuthInput }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [photoTaken, setPhotoTaken] = useState(false);

    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({
            video: { facingMode: { exact: 'environment' } } // Выбор тыльной камеры
        })
            .then(stream => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play();
                    };
                }
                setIsVideoPlaying(true);
            })
            .catch(err => console.error('Ошибка доступа к камере:', err));
    };
     // закоменченный текст исключительно для возможности проверить на вебке компа
    // const startVideo = () => {
    //     navigator.mediaDevices.getUserMedia({ video: true })
    //         .then(stream => {
    //             if (videoRef.current) {
    //                 videoRef.current.srcObject = stream;
    //                 videoRef.current.onloadedmetadata = () => {
    //                     videoRef.current.play();
    //                 };
    //             }
    //             setIsVideoPlaying(true);
    //         })
    //         .catch(err => console.error('Ошибка доступа к камере:', err));
    // };

    const capturePhoto = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (videoRef.current && canvas) {
            // Устанавливаем размеры canvas такие же, как у видео
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;

            // Рисуем кадр с видео на canvas
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            // Создаем Blob из изображения
            canvas.toBlob((blob) => {
                if (blob) {
                    setAuthInput(blob); // Передаем Blob в setAuthInput
                    setPhotoTaken(true);
                    stopVideo(); // Останавливаем видеопоток после снимка
                } else {
                    console.error('Ошибка при создании Blob');
                }
            }, 'image/png');
        }
    };

    const stopVideo = () => {
        const stream = videoRef.current?.srcObject;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
        }
        setIsVideoPlaying(false);
    };

    const handleStartCapture = () => {
        if (videoRef.current && !isVideoPlaying) {
            startVideo();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.videoWrapper}>
                <video
                    ref={videoRef}
                    autoPlay
                    className={styles.video}
                    muted
                    playsInline
                    webkit-playsinline
                />
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
            {!isVideoPlaying && !photoTaken && (
                <button onClick={handleStartCapture} className={styles.button}>
                    {t("Start Camera")}
                </button>
            )}
            {isVideoPlaying && (
                <button onClick={capturePhoto} className={styles.button}>
                    {t("SCAN PASS ID")}
                </button>
            )}
            {photoTaken && <p className={styles.success}>{t("Photo captured successfully!")}</p>}
        </div>
    );
};

export default DocCapture;
