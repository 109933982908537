import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";

import styles from "./CheckoutMobile.module.scss";

import MapModal from "./MapModal/MapModal";
import IconBack from "../../components/Icons/IconBack";
import rootStore from "../../store/RootStore";
import OrderAgine from "../../components/Basket/OrderAgine/OrderAgine";
import AddressInfo from "./AddressInfo/AddressInfo";
import { useTheme } from "../../helpers/ThemeContext";
import DeliveryTimes from "./DeliveryTimes/DeliveryTimes";
import PromoCodeModal from "./PromoCodeModal/PromoCodeModal";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import LocationAdress from "./LocationAdress/LocationAdress";
import { getCurrencySymbol } from "../../helpers/getCurrency";
import { setOrder, removeOrder } from "../../helpers";
import { getAddress, getCord, removeAddress, removeCord } from "../../helpers";

const CheckoutMobile = () => {

  const navigate = useNavigate();
  const [methodPay, setMethodPay] = useState(1);

  const [openPromo, setOpenPromo] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  const [mobile, setMobile] = useState("+77777777");
  const [comment, setComment] = useState("");
  const [needPhone, setNeedPhone] = useState(false);
  const [needComment, setNeedComment] = useState(false);
  const [needAddress, setNeedAddress] = useState(false);
  const [isCheckout, setCheckout] = useState(false);
  const [isErrorCash, setErrorCash] = useState(false);

  const [openDelivery, setOpenDelivery] = useState(false);
  const symbol = getCurrencySymbol(); //{symbol}
  const [placemark, setPlacemark] = useState(
    JSON.parse(getCord()) || [32.05, 34.85]
  );

  const {
    getCheckout,
    lazyInitialize,
    sendCheckout,
    getErrorText,
    getCompletedOrder,
  } = rootStore.checkoutStore;
  const {
    lazyInitialize: updateCard,
    lazyInitialize2: updateCardActive,
    setOpenOrderAgine,
    setTargetOrderCheck,
    getOrderAgine,
  } = rootStore.cartOrderStore;
  const { userData } = rootStore.userStore;
  const { loadCurrentAddress } = rootStore.addressStore;
  const { themeMode } = useTheme();

  const handleClickPromo = () => {
    setOpenPromo(true);
  };
  const handleClickPayments = () => {
    setOpenPayment(true);
  };
  const handleClickLocation = () => {
    setOpenLocation(true);
  };
  const address = JSON.parse(getAddress());

  const noMoney = +userData.profile.balance < +getCheckout.total_price_converted;

  useEffect(() => {
     lazyInitialize();
    loadCurrentAddress(userData?.profile?.country);
    return () => {
      updateCard();
      removeOrder();
      removeAddress();
      updateCardActive();
    };
  }, []);

  const handleSubmit = async () => {
    if (noMoney) {
      setErrorCash(true)

      setTimeout(() => {
        setErrorCash(false);
      }, 2000);
      return
    }
    if (mobile && comment && address?.city) {
      const data = {
        payment_type: methodPay === 1 ? "balance" : "cash",
        address: "Tel Aviv",
        delivery_address: address.id,
        phone_number: mobile,
        courier_comment: comment,
        delivery_coordinates: {
          latitude: placemark[0],
          longitude: placemark[1],
        },
      };
      await sendCheckout(data, setCheckout, setErrorCash);
      removeCord();
      await updateCard();
      await updateCardActive();
    } else {
      if (!mobile) {
        setNeedPhone(true);
        setTimeout(() => {
          setNeedPhone(false);
        }, 2000);
      }
      if (!comment) {
        setNeedComment(true);
        setTimeout(() => {
          setNeedComment(false);
        }, 2000);
      }
      if (!address?.city) {
        setNeedAddress(true);
        setTimeout(() => {
          setNeedAddress(false);
        }, 2000);
      }
    }
    if (mobile && comment && address?.city) {
      setOpenOrderAgine(true);
    }
  };
  if (isCheckout) {
    setOrder(getCompletedOrder);
    setTargetOrderCheck(getCompletedOrder);
  }
  if (isErrorCash) {
    setTimeout(() => {
      setErrorCash(false);
    }, 2000);
  }

  const handleOpenMap = () => {
    if (window.location.pathname.includes("checkout-mobile")) {
      setOpenMap(true);
    }
  };


  return (
    <>
      {!getOrderAgine ? (
        <>
          <div className={styles.backer} onClick={() => navigate(-1)}>
            <IconBack size={20} pure={true} fill={"#009de0"} />
            <div className={styles.shopName}>{getCheckout.shop_name}</div>
            <div className={styles.iBlock}>i</div>
          </div>
          <main className={styles.mainPage}>
            <YMaps query={{ lang: "en_EU" }}>
              <div className={styles.mapBlock} onClick={() => handleOpenMap()}>
                <Map
                  defaultState={{ center: placemark, zoom: 11 }}
                  state={{ center: placemark, zoom: 11 }}
                  width={"100%"}
                >
                  {placemark && <Placemark geometry={placemark} />}
                </Map>
              </div>
            </YMaps>
            {!openMap && (
              <div
                className={styles.mainMapFather}
                onClick={() => setOpenMap(true)}
              ></div>
            )}
            <div className={styles.mainConteiner}>
              <div className={styles.mainBoxBlock}>
                <div className={styles.tabsContainer}>
                  <div className={styles.tabs}>
                    <button
                      className={`${styles.tabButton} ${styles.active}`}
                      data-testid="shopping-cart-tab"
                    >
                      {t("Delivery")}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.imagesBlock} ${
                  needAddress && styles.needAddress
                }`}
                onClick={() => handleClickLocation()}
              >
                <div className={styles.rowBlock}>
                  <div className={styles.leftBlock}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      className={styles.svgImage}
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                      />
                    </svg>
                    <div className={styles.textBlockImage}>
                      <span className={styles.topText}>{address?.city}</span>
                      <span className={styles.downText}>{address?.label}</span>
                    </div>
                    {!address?.city && (
                      <p className={styles.warningText}>
                        {t("Please select address")}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.IconNextBlock}>
                  <svg viewBox="0 0 19 19" className={styles.IconNext}>
                    <path d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                  </svg>
                </div>
              </div>
              <div className={styles.imgBlocksSearch}>
                <input
                  className={`${styles.searchForm} ${
                    needComment && styles.needPhone
                  }`}
                  style={
                    themeMode === "dark" ? { opacity: "1" } : { opacity: "0.5" }
                  }
                  type="text"
                  placeholder={t("Instructions for the courier")}
                  name="name"
                  required-placeholder=""
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className={styles.paymentBlock}>
                <span className={styles.titlePayment}>{t("Payment")}</span>
              </div>
              <button
                className={styles.paymentBtn}
                tabIndex="0"
                onClick={() => handleClickPayments()}
              >
                <div className={styles.mailLeftBlBtn}>
                  <div className={styles.paymentBtnLeftBLock}>
                    {methodPay === 1 ? (
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.paymentBtnLeftImg}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M24 10.75V17a3 3 0 0 1-2.824 2.995L21 20H3a3 3 0 0 1-2.995-2.824L0 17v-6.25a.25.25 0 0 1 .25-.25h23.5a.25.25 0 0 1 .25.25ZM8 14H5a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2ZM21 3a3 3 0 0 1 2.995 2.824L24 6v1.25a.25.25 0 0 1-.25.25H.25A.25.25 0 0 1 0 7.25V6a3 3 0 0 1 2.824-2.995L3 3h18Z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                        height="24px"
                        width="24px"
                        version="1.1"
                        id="Capa_1"
                        viewBox="0 0 435 435"
                      >
                        <g>
                          <path d="M452.585,98.818H58.465c-5.247,0-9.5,4.253-9.5,9.5v14.98h-14.98c-5.247,0-9.5,4.253-9.5,9.5v14.979H9.5   c-5.247,0-9.5,4.253-9.5,9.5v196.49c0,5.247,4.253,9.5,9.5,9.5h394.117c5.247,0,9.5-4.253,9.5-9.5v-14.98h14.978   c5.247,0,9.5-4.253,9.5-9.5v-14.98h14.99c5.247,0,9.5-4.253,9.5-9.5v-196.49C462.085,103.071,457.832,98.818,452.585,98.818z    M46.808,166.777C43.341,180.385,32.607,191.118,19,194.586v-27.809H46.808z M394.117,194.586   c-13.609-3.467-24.343-14.201-27.811-27.809h27.811V194.586z M346.917,166.777c4.027,24.105,23.095,43.173,47.201,47.199v83.09   c-24.107,4.026-43.175,23.095-47.201,47.201H66.198C62.172,320.162,43.105,301.094,19,297.067v-83.091   c24.105-4.028,43.171-23.094,47.198-47.199H346.917z M19,316.457c13.608,3.468,24.342,14.202,27.808,27.811H19V316.457z    M366.306,344.267c3.467-13.609,14.202-24.344,27.811-27.811v27.811H366.306z M418.595,319.787h-5.478v-162.51   c0-5.247-4.253-9.5-9.5-9.5H43.484v-5.479h375.11V319.787z M443.085,295.307h-5.49v-162.51c0-5.247-4.253-9.5-9.5-9.5H67.965v-5.48   h375.12V295.307z" />
                          <path d="M161.564,311.56c11.817,15.934,27.797,24.708,44.994,24.708c17.198,0,33.178-8.775,44.995-24.708   c11.187-15.083,17.347-34.984,17.347-56.038c0-21.055-6.16-40.956-17.347-56.038c-11.817-15.934-27.797-24.708-44.995-24.708   c-17.197,0-33.177,8.775-44.994,24.708c-11.187,15.082-17.347,34.983-17.347,56.038   C144.218,276.576,150.378,296.478,161.564,311.56z M206.559,317.268c-9.053,0-17.463-3.981-24.423-10.77   c1.486-12.161,11.868-21.612,24.423-21.612c12.556,0,22.938,9.451,24.424,21.612C224.022,313.287,215.612,317.268,206.559,317.268z    M206.562,265.49c-8.022,0-14.549-6.526-14.549-14.548c0-8.022,6.526-14.549,14.549-14.549c8.021,0,14.548,6.526,14.548,14.549   C221.109,258.963,214.583,265.49,206.562,265.49z M206.559,193.776c23.898,0,43.342,27.699,43.342,61.747   c0,11.458-2.209,22.189-6.042,31.396c-3.208-5.279-7.508-9.82-12.579-13.322c5.479-5.973,8.83-13.929,8.83-22.655   c0-18.499-15.05-33.549-33.548-33.549c-18.499,0-33.549,15.05-33.549,33.549c0,8.725,3.35,16.68,8.829,22.653   c-5.072,3.502-9.374,8.044-12.582,13.324c-3.833-9.206-6.042-19.938-6.042-31.396C163.218,221.475,182.66,193.776,206.559,193.776z   " />
                        </g>
                      </svg>
                    )}
                  </div>
                  <div className={styles.paymentBtnMiddle}>
                    <div className={styles.paymentBtnMiddleLeft}>
                      {t("Selected a payment method")}:{" "}
                      {methodPay === 1
                        ? t("Balance").toLowerCase()
                        : t("Cash").toLowerCase()}
                    </div>
                    <div className={styles.paymentBtnMiddleRight}>
                      {t("Tap here to switch")}
                    </div>
                  </div>
                </div>
                <div className={styles.IconNextBlock}>
                  <svg viewBox="0 0 19 19" className={styles.IconNext}>
                    <path d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                  </svg>
                </div>
              </button>
              <button
                className={styles.promoBtn}
                tabIndex="0"
                onClick={() => handleClickPromo()}
              >
                <div className={styles.mailLeftBlBtn}>
                  <div className={styles.paymentBtnLeftBLock}>
                    <div className={styles.customSvg}>w</div>
                  </div>
                  <div className={styles.paymentBtnMiddle}>
                    <div className={styles.paymentBtnMiddleLeft}>
                      {t("Wolt promo code")}
                    </div>
                    <div className={styles.paymentBtnMiddleRight}>
                      {t("Enter code to claim your benefits")}
                    </div>
                  </div>
                </div>
                <div className={styles.IconNextBlock}>
                  <svg viewBox="0 0 19 19" className={styles.IconNext}>
                    <path d="M12.1768 13.409C12.0791 13.5066 11.9209 13.5066 11.8232 13.409L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12.1768 13.409Z"></path>
                  </svg>
                </div>
              </button>
              <div className={styles.priceInBlock}>
                <span className={styles.priceInUp}>{t("Price in")} {symbol}</span>
                <span className={styles.priceInDown}>
                  {t("including taxes")}
                </span>
              </div>
              <div className={styles.paymentBlock}>
                <span className={styles.titleFees}>{t("How fees work")}</span>
              </div>
              <div className={styles.costList}>
                <div className={styles.upMiddleBlock}>
                  <dt className={styles.upMiddleBlockText}>
                    {t("Item subtotal")}
                  </dt>
                  <dd className={styles.upMiddleBlockText}>
                    {/*<span>{getCheckout.total_price} {getCheckout.currency}</span></dd>*/}
                    <span>
                      {getCheckout.total_price_converted || getCheckout.total}{" "}
                      {symbol}
                    </span>
                  </dd>
                </div>
                <div className={styles.upMiddleBlock}>
                  <dt className={styles.upMiddleBlockText}>{t("Delivery")}</dt>
                  <dd className={styles.upMiddleBlockText}>
                    {/*<span>{getCheckout.delivery} {getCheckout.currency}</span></dd>*/}
                    <span>
                      {getCheckout.delivery} {symbol}
                    </span>
                  </dd>
                </div>
                <div className={styles.upMiddleBlock}>
                  <dt className={styles.upMiddleBlockText}>{t("Total")}</dt>
                  <dd className={styles.upMiddleBlockText}>
                    <span>
                      {getCheckout.total_price_converted || getCheckout.total}{" "}
                      {symbol}
                    </span>
                  </dd>
                  {/*<span>{getCheckout.total} {getCheckout.currency}</span></dd>*/}
                </div>
              </div>
              <div className={styles.excludedBlock}>
                <span className={styles.excludedTitle}>
                  {t("Restricted items are excluded.")}
                </span>
              </div>
              <div className={styles.excludedDeliveryBlock}>
                <span className={styles.excludedDelivery}>
                  0{symbol} Delivery fee
                </span>
              </div>
              <div className={styles.fakeDownBlock}></div>
              <button
                aria-disabled="false"
                type="button"
                className={`${styles.footerBtn} ${isCheckout && styles.good} ${
                  isErrorCash && styles.error
                }`}
                onClick={() => handleSubmit()}
              >
                {isCheckout ? (
                  <div className={styles.footerBtnMain}>{t("Success")}</div>
                ) : (
                  <div className={styles.footerBtnMain}>
                    {isErrorCash
                      ? getErrorText
                        ? getErrorText
                        : t("Not have money!")
                      : needPhone
                      ? t("Input Phone!")
                      : needComment
                      ? t("Input Comment!")
                      : needAddress
                      ? t("Select Address!")
                      : t("Checkout")}
                  </div>
                )}
              </button>
            </div>
            <PromoCodeModal isOpen={openPromo} setIsOpen={setOpenPromo} />
            <PaymentMethods
              isOpen={openPayment}
              setIsOpen={setOpenPayment}
              method={methodPay}
              setMethod={setMethodPay}
            />
            <LocationAdress isOpen={openLocation} setIsOpen={setOpenLocation} />
            <AddressInfo isOpen={openAddress} setIsOpen={setOpenAddress} />
            <DeliveryTimes isOpen={openDelivery} setIsOpen={setOpenDelivery} />
            <MapModal
              isOpen={openMap}
              setIsOpen={setOpenMap}
              placemark={placemark}
              setPlacemark={setPlacemark}
            />
          </main>
        </>
      ) : (
        <OrderAgine isCheckout={true} />
      )}
    </>
  );
};

export default observer(CheckoutMobile);
