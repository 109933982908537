import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';
import styles from './FaceVerification.module.scss';
import {t} from "i18next";

const FaceVerification = ({ setAuthInput }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        const loadModels = async () => {
            try {
                const MODEL_URL = '/models'; // Путь к моделям
                await Promise.all([
                    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                    faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                    faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                ]);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка загрузки моделей:', error);
            }
        };

        loadModels();
    }, []);

    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play();
                    };
                }
                setIsVideoPlaying(true);
            })
            .catch(err => console.error('Ошибка доступа к камере:', err));
    };

    const handleVideoPlay = async () => {
        if (!isVideoPlaying || authSuccess) return;

        intervalRef.current = setInterval(async () => {
            if (videoRef.current) {
                const detections = await faceapi.detectAllFaces(
                    videoRef.current,
                    new faceapi.TinyFaceDetectorOptions()
                ).withFaceLandmarks();

                if (detections.length > 0) {
                    console.log('Лицо обнаружено:', detections);
                    handleAuthSuccess(); // Аутентификация успешна
                }
            }
        }, 100);
    };

    const handleAuthSuccess = () => {
        setAuthSuccess(true);
        setIsVideoPlaying(false);
        savePhoto();
        // Остановить видеопоток
        const stream = videoRef.current?.srcObject;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
        }

        // Очистить интервал
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        // Сохранить фото
    };

    const savePhoto = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (videoRef.current && canvas) {
            // Устанавливаем размеры canvas такие же, как у видео
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;

            // Рисуем кадр с видео на canvas
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            // Создаем ссылку для скачивания
            canvas.toBlob((blob) => {
                if (blob) {
                    setAuthInput(blob); // Передача Blob в setAuthInput
                } else {
                    console.error('Ошибка при создании Blob');
                }
            }, 'image/png');
        } else {
            console.error('Ошибка при захвате изображения');
        }
    };


    const handleStartVerification = () => {
        if (videoRef.current) {
            startVideo();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.videoWrapper}>
                <video
                    ref={videoRef}
                    autoPlay
                    onPlay={handleVideoPlay}
                    className={styles.video}
                    muted
                    playsInline
                    webkit-playsinline
                />
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
            {loading && <p className={styles.loading}>{t("Loading models...")}</p>}
            <button
                onClick={handleStartVerification}
                disabled={isVideoPlaying || authSuccess}
                className={authSuccess ? styles.buttonDis : styles.button}
            >
                {t("Start authentication")}
            </button>
            {authSuccess && <p className={styles.success}>{t("Authentication was successful!")}</p>}
        </div>
    );
};

export default FaceVerification;

