import React, { useEffect } from "react";
import { observer } from "mobx-react";
import styles from "./WorkedHistory.module.scss";
import DiscoveryParent from "../../../components/DiscoveryParent/DiscoveryParent";
import rootStore from "../../../store/RootStore";
import HistoryCart from "../../../components/HistoryCart/HistoryCart";
import { t } from "i18next";

const WorkedHistory = () => {
  const { getWorkedOrders, loadWorkedOrders } = rootStore.curierStore;

  useEffect(() => {
    loadWorkedOrders();
  }, []);
  const arr = [...getWorkedOrders];
  const sortedArr = arr.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <DiscoveryParent>
      <div className={styles.mainBlock}>
        <h1 className={styles.titleMenu}>{t("Orders in progress")}</h1>
        <div className={styles.lvl1}>
          {sortedArr.map((el, index) => (
            <HistoryCart data={el} index={index} key={el.id} />
          ))}
        </div>
      </div>
    </DiscoveryParent>
  );
};

export default observer(WorkedHistory);
