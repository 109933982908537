import React, { useState } from 'react';

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import styles from './RedeemCodeTab.module.scss'

import img from "./img.png"
import img_dark from "./img_dark.png"
import IconBack from "../../Icons/IconBack";
import { useTheme } from "../../../helpers/ThemeContext";

const RedeemCodeTab = () => {
    const navigate = useNavigate();
    const { themeMode } = useTheme();

    const [value, setValue] = useState('');

    const goBack = () => {
        navigate('/discovery/me', { replace: true });
    };

    return (
        <>
            <div className={styles.goBackButton} onClick={goBack}>
                <IconBack size={20} pure={true} fill={"#009de0"}/>
            </div>
        <div className={styles.lvl1}>
            <div className={styles.lvl2}>
                <div className={styles.lvl3}>
                    <div className={styles.mainConteiner}>
                        <div className={styles.mainBlock}>
                            <div className={styles.mainImg}>
                                <div className={styles.mainBlockImg}>
                                    {themeMode === "light" ? <img style={{width: "290px"}} src={img} alt="React Logo"/> : <img style={{width: "290px"}} src={img_dark} alt="React Logo"/>}
                                </div>
                            </div>
                            <div className={styles.container}>
                                <h2 className={styles.title}>
                                    <span>{t("Redeem code")}</span>
                                </h2>
                                <div className={styles.description}>
                                    <p className={styles.descText}>
                                        <span>{t("If you have a Wolt code, enter it below to get your gift!")}</span>
                                    </p>
                                </div>
                                <div className={styles.inputContainer}>
                                    <div>
                                        <div className={styles.inputWrapper}>
                                            <input
                                                placeholder={t("Enter promo code…")}
                                                type="text"
                                                className={styles.input}
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                            <button
                                                aria-disabled="false"
                                                type="button"
                                                className={styles.button}
                                                data-localization-key="user.redeem"
                                            >
                                                {t("Redeem")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </>
    );
};

export default RedeemCodeTab;