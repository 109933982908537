import {makeAutoObservable} from 'mobx';
import api from "../api/api";
import {getToken, getUser, removeToken, removeUser, setToken, setUser} from "../helpers";
import {checkAuthError} from "../api/handlerErrors/getShopData";
import {changeCurrency} from "../api/addContent/changeCurrency";
import cartOrderStore from "./CartOrderStore";
import checkoutStore from "./CheckoutStore";

const DEFAULT_PROFILE = {
    "id": 1,
    "photo": null,
    "balance": null,
    "phone_number": null,
    "country" : null,
    "wolt_tokens": null,
    "referral_code": null,
    "username": null,
    "role": null,
    "currency_type": "USD",
}

class UserStore {
    user = {
        authorized: false,
        home: "Tel Aviv",
        profile: DEFAULT_PROFILE
    };

    transactions = []
    history = []
    countrys = []
    languages= []
    subscribeData = {}
    statusBuy = ''
    depositeStatus = ''

    errorReset = {
        state: 0,
        message: ""
    }

    resetForEmail = {
        state: 0,
        message: ""
    }

    statusPay = ''

    payData = {}

    errorLogin = ''
    seccessLogin = false

    isLoader = false

    constructor() {
        makeAutoObservable(this);
    }
    get currentCurrencyData() {
       return this.user.profile.currency_type
    }
    updateCurrency =  async (currency) => {
        await changeCurrency({
            currency: currency
        })
        this.currentCurrency = currency;
        this.user.profile.currency_type = currency
    }
    get errorRequest() {
        return this.errorLogin;
    }

    get getErrorReset() {
        return this.errorReset;
    }

    get getIsLoader() {
        return this.isLoader;
    }

    get getSuccessLogin(){
        return this.seccessLogin;
    }

    get userData() {
        return this.user;
    }

    get countrysList() {
        return this.countrys;
    }

    get languageList() {
        return this.languages;
    }

    get historyList() {
        return this.history;
    }

    updateUser(user) {
        this.user = user;
    }
    updateAuth(bool) {
        this.user.authorized = bool;
    }
    getHome(){
        return this.user.home;
    }
    setHome(city) {
        this.user.home = city;
    }
    setIsLoader(state) {
        this.isLoader = state;
    }
    setErrorStatus(status){
        this.errorLogin = status
    }
    setSuccessLogin(status){
        this.seccessLogin = status
    }
    setPayData(data) {
        this.payData = data
    }

    setLogOut = async () => {
        this.user.authorized = false
        this.user.profile = DEFAULT_PROFILE
        removeToken()
        removeUser()
        localStorage.removeItem('currentShop');
        localStorage.removeItem('selectedTab');
    }


    register = async(email, username, password, confirm_password, telegram_id, openCloseR) => {
        api.post('user/register/', {user: {email, username, password, confirm_password, telegram_id}}).then(response => {
            setToken(response.data.user.token)
            this.updateAuth(true)
            setUser(this.userData)
            this.setErrorStatus(false)
            this.setSuccessLogin(true)
            checkAuthError(response.data)
            this.setIsLoader(true)
            cartOrderStore.lazyInitialize2()
            // TODO: lazyInitialize, Вызов вызывает ошибку, закомментил
            // checkoutStore.lazyInitialize()
            setTimeout(() => {
                this.setSuccessLogin(false)
                openCloseR();
                this.setIsLoader(false)
            }, 2000);
        }).catch(error => {
            console.log("ERROR")
            this.setErrorStatus(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]);
            setTimeout(() => {
                this.setErrorStatus('');
            }, 3000);
        })
    }

    login = async (email, password, openCloseL) => {
        await api.post('user/login/', {user: { email, password }}).then(response => {
            setToken(response.data.user.token)
            this.setSuccessLogin(true)
            this.user.authorized = true
            this.user.profile = response.data.user.profile
            setUser(this.user)
            this.setErrorStatus(false)
            checkAuthError(response.data)
            this.getProfile()
            this.setIsLoader(true)
            cartOrderStore.lazyInitialize2()
            // TODO: lazyInitialize, Вызов вызывает ошибку, закомментил
            // checkoutStore.lazyInitialize()
            setTimeout(() => {
                this.setSuccessLogin(false)
                openCloseL();
                this.setIsLoader(false)
            }, 2000);
        }).catch(error => {
            console.log("ERROR")
            this.setErrorStatus(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]);
            setTimeout(() => {
                this.setErrorStatus('');
            }, 3000);
        })
    }

    checkAuth = () => {
        if (getToken() && getUser()){
            this.updateAuth(true)
            cartOrderStore.lazyInitialize2()
            // TODO: lazyInitialize, Вызов вызывает ошибку, закомментил
            // checkoutStore.lazyInitialize()
            this.user.profile = JSON.parse(getUser()).profile
        } else {
            removeToken()
            removeUser()
        }
    }

    getProfile = async() => {
        if(!this.user.authorized) return
        api.get('user/profile/').then(response => {
            this.user.profile = response.data[0]
            setUser(this.user)
            localStorage.setItem('i18nextLng', response.data[0].language);
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    getCountry = async() => {
        api.get('country-choices/').then(response => {
            this.countrys = response.data
        }).catch(error => {
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    getLanguages = async() => {
        api.get('language-choices/').then(response => {
            this.languages = response.data
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    changePhoneNumber = async(phone) => {
        api.patch(`user/profile/${this.user.profile.id}/`, {"phone_number": phone}).then(response => {
            this.user.profile = response.data
        }).catch(error => {
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    changePhote = async(data) => {
        api.patch(`user/profile/${this.user.profile.id}/`, data).then(response => {
            this.user.profile = response.data
        }).catch(error => {
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    changeCountry = async(value) => {
        api.patch(`user/profile/${this.user.profile.id}/`, {"country": value}).then(response => {
            this.user.profile = response.data
        }).catch(error => {
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    changeLanguage = async(value) => {
        api.patch(`user/profile/${this.user.profile.id}/`, {"language": value}).then(response => {
            this.user.profile = response.data
        }).catch(error => {
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }

    getHistory = async() => {
        api.get('user/purchase-history/').then(response => {
            this.history = response.data
        }).catch(error => {
            console.log(error)
            checkAuthError(error.response.data)
            throw error.response.data;
        })
    }
    get isAuth() {
        return this.user.authorized
    }
    get isSuperAdmin() {
        return this.user.profile.role === "superadminshop"
    }
    get isCurier() {
        return this.user.profile.role === "courier"
    }
    sendVerification = async(forma) => {
        api.patch('user/verification/submit_face_and_video/', forma).then(response => {
        }).catch(error => {
            api.patch('user/verify/', forma).then(response => {
            }).catch(error => {
                checkAuthError(error.response.data)
                throw error.response.data;
            })
            checkAuthError(error.response.data)
        })
    }

    sendVerificationDoc = async(forma) => {
        api.patch('/user/verification/submit_passport/', forma).then(response => {
        }).catch(error => {
            api.patch('user/verify/', forma).then(response => {
            }).catch(error => {
                checkAuthError(error.response.data)
                throw error.response.data;
            })
            checkAuthError(error.response.data)
        })
    }

    get getTransactions() {
        return this.transactions;
    }
    setTransactions(data){
        this.transactions = data
    }

    loadTransactionsals = async() => {
        api.get('transactions/user-invoices-history/').then(response => {
            this.setTransactions(response.data)
        }).catch(error => {
            checkAuthError(error.response.data)
        })
    }

    setStatusPay(data) {
        if (data.detail){
            this.statusPay = `${data.detail}: ${data.time}`
            setTimeout(() => {
                this.statusPay = ''
            }, 1000)
        }
    }
    get getStatusPay() {
        return this.statusPay;
    }

    topUpBalance = async(amount, navigate) => {
        api.post('transactions/create-invoice/', {amount: amount}).then(response => {
            this.setStatusPay(response.data)
            setTimeout(() => {
                this.loadTransactionsals()
                navigate(-1)
            }, 200)
        }).catch(error => {
            this.setStatusPay(error.response.data)
            checkAuthError(error.response.data)
        })
    }
    get getPayData() {
        return this.payData
    }

    setSubscribeData(data) {
        this.subscribeData = data
    }
    get getSubscribeData() {
        return this.subscribeData;
    }

    checkSubscribe = async() => {
        api.get('admin_seller_shop/my-shop/get-my-settings/').then(response => {
            this.setSubscribeData(response.data)
        }).catch(error => {
            checkAuthError(error.response.data)
        })
    }

    setStatusBuy(data) {
        this.statusBuy = data
        setTimeout(() => {
            this.statusBuy = ''
        }, 3000)
    }
    get getStatusBuy() {
        return this.statusBuy;
    }

    buySubscribe = async() => {
        api.post('admin_seller_shop/my-shop/deduct-seller-deposit/').then(response => {
            this.setStatusBuy(response.data)
        }).catch(error => {
            this.setStatusBuy(error.response.data.detail)
            checkAuthError(error.response.data)
        })
    }

    setDepositeStatus(data) {
        if (data.success){
            this.depositeStatus = 1
        } else {
            this.depositeStatus = 2
        }
        setTimeout(() => {
            this.depositeStatus = ''
        }, 3000)
    }
    get getDepositeStatus() {
        return this.depositeStatus;
    }

    checkDeposite = async(id) => {
        api.post('transactions/check-payment-status/', {invoice_ids: [id]}).then(response => {
            this.setDepositeStatus(response.data)
        }).catch(error => {
            checkAuthError(error.response.data)
        })
    }


    setResetForEmail(data) {
        return this.resetForEmail = data;
    }
    get getResetForEmail() {
        return this.resetForEmail;
    }

    resetPassword = async(email) => {
        api.post('user/send-telegram-code/', {email: email}).then(response => {
            this.setResetForEmail({
                state: 1,
                message: response.data.message
            });
            console.log(this.errorReset)
        }).catch(error => {
            this.setResetForEmail({
                state: 2,
                message: error.response.data.error
            });
            setTimeout(() => {
                this.setResetForEmail({
                    state: 0,
                    message: ""
                })
            }, 1500)
        })
    }

    setErrorReset(data) {
        this.errorReset = data
    }

    resetPasswordFinal = async(email, code, new_password, setState) => {
        api.post('user/reset-password-with-code/', {email: email, code: code, new_password: new_password}).then(response => {
            this.setErrorReset({
                state: 1,
                message: response.data.message
            });
            setTimeout(() => {
                setState(false)
                this.setErrorReset({
                    state: 0,
                    message: ""
                })
            }, 1500)
            this.setResetForEmail({
                state: 0,
                message: ""
            })
            console.log(this.errorReset)
        }).catch(error => {
            console.log(error)
            this.setErrorReset({
                state: 2,
                message: error.response.data.error
            });
            setTimeout(() => {
                this.setErrorReset({
                    state: 0,
                    message: ""
                })
            }, 1500)
        })
    }

}

const userStore = new UserStore();
export default userStore;