import React from 'react';
import styles from "./ModeratorBodyMenuTable.module.scss";
import {t} from "i18next";

const CreateProductButton = ({ onClick }) => {
    return (
        <div className={styles.createProductBtnBlock}>
            <button
                data-label="Создать товар"
                id="createFirstProduct"
                data-testid="createProduct"
                onClick={onClick}
                className={styles.createProductBtn}
            >
                <span>{t("Create product")}</span>
            </button>
        </div>
    );
};

export default CreateProductButton;
