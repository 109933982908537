import React from 'react';
import styles from "./ModalMobileMoreInforation.module.scss"
import {Modal} from "@mui/material";
import {observer} from "mobx-react";
import rootStore from "../../store/RootStore";
import {Map, YMaps} from "@pbe/react-yandex-maps";
import {t} from "i18next";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalMobileMoreInforation = observer(() => {
    const {shopData, getOpenMoreInfo, setOpenMoreInfo} = rootStore.shopStore;

    return (
        <div>
            <Modal
                open={getOpenMoreInfo}
                onClose={() => setOpenMoreInfo(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id="modal-info-mobile"
            >
                <div className={styles.mainContainer}>
                    <div className={styles.mainBlock}>
                        <aside className={styles.container}>
                            <div className={styles.closeBtnConteiner}>
                                <button className={styles.buttonBlock} data-backdrop="transparent"
                                        data-size="medium" data-variant="neutral" type="button"
                                        aria-label="Close the dialog" data-test-id="modal-close-button"
                                        onClick={() => setOpenMoreInfo(false)}>
                                    <div className={styles.btnFakeBlock}></div>
                                    <div className={styles.btnConteinerInclude}>
                                        <svg viewBox="0 0 24 24" className={styles.imgCloseBtn}>
                                            <path
                                                d="M2.44 19.44a1.5 1.5 0 002.121 2.121l7.262-7.26a.25.25 0 01.354 0l7.262 7.262a1.5 1.5 0 102.122-2.12l-7.26-7.266a.25.25 0 010-.354l7.262-7.262a1.5 1.5 0 00-2.124-2.12L12.177 9.7a.25.25 0 01-.354 0L4.561 2.44A1.5 1.5 0 002.44 4.56L9.7 11.823a.25.25 0 010 .354L2.44 19.44z"></path>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                            <div className={styles.mainConteiner}>
                                <div className={styles.mainInfoConteiner}>
                                    <div className={styles.modalContentContainer}>
                                        <div className={styles.venueInformationModal}>
                                            <div className={styles.header}>
                                                <h2 className={styles.largeHeader}>{shopData.ShopName}</h2>
                                                <div className={styles.openingTimes}>
                                                </div>
                                                <p className={styles.description}>{shopData.Description}</p>
                                            </div>
                                            <div className={styles.termBlock}>
                                                <span className={styles.termTitle}>{t("Terms and conditions")}</span>
                                                <span
                                                    className={styles.termDescription}>{t("Additional terms for grocery products and other consumer goods apply.")}</span>
                                            </div>
                                            <div className={styles.address}>
                                                <h3 className={styles.title}>{t("Address")}</h3>
                                                <p className={styles.info}>{t("This is a virtual venue")}</p>
                                                <p className={styles.info}>{shopData.Addres}</p>
                                            </div>
                                            <div className={styles.openingTimes2}>
                                                <div className={styles.timesBlockUp}>
                                                    <h3 className={styles.title}>{t("Opening hours")}"</h3>
                                                    <h2 className={styles.descTime}>{t("Venue")}</h2>
                                                </div>
                                                <div className={styles.tableConteiner}>
                                                    <table className={styles.timeTable}>
                                                        <tbody>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Monday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Tuesday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Wednesday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Thursday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Friday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Saturday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        <tr className={styles.line}>
                                                            <td className={styles.day}>{t("Sunday")}</td>
                                                            <td className={styles.timeRange}>{t("All day")}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className={styles.contact}>
                                                <h3 className={styles.title}>{t("Contact")}</h3>
                                                <p className={styles.info}>{shopData.Contact_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default ModalMobileMoreInforation;