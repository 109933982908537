import React, {useState} from 'react';
import styles from './ChangeMobile.module.scss'

import IconBack from "../../components/Icons/IconBack";
import img from "../../components/PersonalLab/RedeemCodeTab/img.png";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import MobileAccept from "./MobileAccept";
import {useNavigate} from "react-router-dom";

const ChangeMobile = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState("")
    const [state, setState] = useState(false)

    const handleClick = () => {
        setState(true)
    }

    return (
        <>
            <div className={styles.backer} style={{
                "position": "fixed",
                "width": "32px",
                "height": "32px",
                "zIndex": 9999999,
                "top": "1%",
                "left": "3%",
            }}
                 onClick={() => navigate(-1)}><IconBack size={20} pure={true} fill={"#009de0"}/></div>
            <div className={styles.lvl3}>
                <div className={styles.mainConteiner}>
                    <div className={styles.mainBlock}>
                        <div className={styles.container}>
                            <h2 className={styles.title}>
                                <span>Mobile number</span>
                            </h2>
                        </div>
                        <div style={{position: "relative"}}>
                            <PhoneInput
                                inputStyle={{width: "100%", height: "3rem", fontSize: "1rem"}}
                                defaultCountry="ru"
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                            />
                            {phone.length > 4 && <button className={styles.clearBtn} onClick={() => setPhone("")}>x</button>}
                        </div>
                        <div className={styles.mainImg}>
                            <div className={styles.mainBlockImg}>
                                <img src={img} alt className={styles.img}/>
                            </div>
                        </div>
                        <button className={styles.btnMain} onClick={() => handleClick()}>Save</button>
                    </div>
                </div>
            </div>
            { state && <MobileAccept setState={setState} phone={phone}/> }
        </>
    );
};

export default ChangeMobile;