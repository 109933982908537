export function setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
}

export function setActivNavModer(activBtn) {
    localStorage.setItem('activModerBtn', JSON.stringify(activBtn));
}

export function getActivNavModer() {
    return localStorage.getItem('activModerBtn');
}

export function setOrder(targetOrder) {
    localStorage.setItem('targetOrder', JSON.stringify(targetOrder));
}

export function setAddress(address) {
    localStorage.setItem('address', JSON.stringify(address));
}

export function setCord(value) {
    return localStorage.setItem('coordination', JSON.stringify(value));
}

export function getOrder() {
    return localStorage.getItem('targetOrder');
}

export function getCord() {
    return localStorage.getItem('coordination');
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getAddress() {
    return localStorage.getItem('address');
}

export function removeToken() {
    localStorage.removeItem('token');
}

export function removeCord() {
    return localStorage.removeItem('coordination');
}

export function removeOrder() {
    return localStorage.removeItem('targetOrder');
}

export function setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
    return localStorage.getItem('user');
}

export function removeUser() {
    localStorage.removeItem('user');
}
export function removeAddress() {
    localStorage.removeItem('address');
}

export const unknownImage = "https://img.freepik.com/premium-vector/flat-design-no-data-illustration_23-2150527118.jpg?w=740";