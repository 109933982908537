import React, {useState} from 'react';
import {observer} from "mobx-react";
import ModeratorBodyMenuTable from "./ModeratorBodyMenuTable/ModeratorBodyMenuTable";
import ModeratorBodyMenuNavigate from "./ModeratorBodyMenuNavigate/ModeratorBodyMenuNavigate";
import CreateCategoryModal from "./CreateCategoryModal/CreateCategoryModal";
import CreateProductModal from "./CreateProductModal/CreateProductModal";
import EditProductModal from "./CreateProductModal/EditProductModal";
import rootStore from "../../../store/RootStore";

const ModeratorBodyMenu = observer(() => {
    const {getIfOpenCreateCatalog, getIfOpenCreateProduct, getIsOpenEditProduct,getIsOpenSettingShop} = rootStore.moderatorPanelStore;
    const [state,setState] = useState(false);

    return (
        <>
            {
                !getIsOpenSettingShop && getIfOpenCreateCatalog ?
                    (
                        <CreateCategoryModal setState={setState}/>
                    ) : ""
            }
            { !getIsOpenSettingShop && getIsOpenEditProduct ?
                <EditProductModal setState={setState}/> : ""
            }
            {
                !getIsOpenSettingShop &&getIfOpenCreateProduct ?
                    (
                        <CreateProductModal />
                    ) :
                    (
                        !getIsOpenSettingShop &&!getIsOpenEditProduct? <>
                            <ModeratorBodyMenuNavigate state={state}/>
                            <ModeratorBodyMenuTable />
                        </>:""
                    )
            }
        </>
    );
});

export default ModeratorBodyMenu;