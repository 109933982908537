const MENU = {
    Menu: "Menu",
    Setting: "Setting",
    Subscribe: "Subscribe",
    Ingredients: "Ingredients",
    Modifiers: "Modifiers",
    Remains: "Remains",
    ProductMovement: "Product movement",
    Shops: "Shops",
    Curiers: "Curiers",
    Statistics: "Statistics",
    StatisticsCurrent: "StatisticsCurrent",
    Orders: "Orders"
}

export default MENU;