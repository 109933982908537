import React from 'react';
import styles from "./Slides.module.scss"

const Slides = ({data}) => {
    return (
        <div className={styles.mainConteiner}>
            <img className={styles.imgBody} src={process.env.REACT_APP_MEDIA_URL+data?.shop_images[0]?.ImageUrl}
                 alt={data.shop_images.length ? data.shop_images[0].AltText : ""}/>
        </div>
    );
};

export default Slides;