import React, {useState} from 'react';

import {observer, Provider} from "mobx-react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import './App.scss';

import MainPage from "./pages/MainPage/MainPage";
import NotFount from "./components/NotFound/NotFount";
import MainBody from "./components/MainBody/MainBody";
import DiscoveryWrapper from "./pages/DiscoveryWrapper/DiscoveryWrapper";
import Restaurant from "./components/Restaurants/Restaurant";
import Stores from "./components/Stores/Stores";
import RestoranBody from "./pages/RestoranBody/RestoranBody";
import rootStore from "./store/RootStore";
import DiscoveryBody from "./pages/DiscoveryBody/DiscoveryBody";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StoresBody from "./pages/StoresBody/StoresBody";
import PersonalInfo from "./pages/PersonalInfo/PersonalInfo";
import PaymentMethods from "./components/PersonalLab/PaymentMethods/PaymentMethods";
import AddresTab from "./components/PersonalLab/AddresTab/AddresTab";
import OrderHistory from "./components/PersonalLab/OrderHistory/OrderHistory";
import PersonalInfoTab from "./components/PersonalLab/PersonalInfoTab/PersonalInfoTab";
import EarnWoltCreaditsTab from "./components/PersonalLab/EarnWoltCreaditsTab/EarnWoltCreaditsTab";
import RedeemCodeTab from "./components/PersonalLab/RedeemCodeTab/RedeemCodeTab";
import SettingsTab from "./components/PersonalLab/SettingsTab/SettingsTab";
import CheckoutBody from "./pages/CheckoutBody/CheckoutBody";
import ModeratorPanel from "./pages/ModeratorPanel/ModeratorPanel";
import SearchPage from "./pages/SearchPage/SearchPage";
import AccountMobile from "./pages/PersonalInfo/AccountMobile/AccountMobile";
import LoginRegister from "./pages/LoginRegister/LoginRegister";
import CreditsTokens from "./pages/CreditsTokens/CreditsTokens";
import CheckoutMobile from "./pages/CheckoutMobile/CheckoutMobile";
import {ThemeProvider} from "./helpers/ThemeContext";
import ChangeMobile from "./pages/ChangeMobile/ChangeMobile";
import AnimatedCircle from "./components/AnimatedCircle";
import OrderCard from "./pages/OrderCard/OrderCard";
import CurierWrapper from "./pages/CurierPanel/CurierWrapper";
import ActualHistory from "./pages/CurierPanel/ActualHistory/ActualHistory";
import WorkedHistory from "./pages/CurierPanel/WorkedHistory/WorkedHistory";
import CourierFinances from "./pages/CurierPanel/CourierFinances/CourierFinances";
import CourierDeliveries from "./pages/CurierPanel/CourierFinances/components/CourierDeliveries/CourierDeliveries";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import BalancePage from "./components/PersonalLab/BalancePage/BalancePage";
import AddresPage from "./pages/AddresPage/AddresPage";
import OrderCardMain from "./pages/OrderCard/OrderCardMain";
import TransactionsPage from "./pages/TransactionsPage/TransactionsPage";
import TransactionMainCard from "./pages/TransactionsPage/TransactionMainCard/TransactionMainCard";
import ChatCurier from "./components/Basket/ChatCurier/ChatCurier";
import OrderCardActual from "./pages/OrderCard/OrderCardActual";
import OrderCardWorked from "./pages/OrderCard/OrderCardWorked";
import InfoOrderCardWorked from "./components/InfoOrderCardWorked/InfoOrderCardWorked";
import VerificationPage2 from "./pages/VerificationPage2/VerificationPage2";
import VerificationPage3 from "./pages/VerificationPage3/VerificationPage3";

function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { userData } = rootStore.userStore;
    return (
      <Provider rootStore={rootStore}>
          <ThemeProvider>
              <BrowserRouter>
                  <AnimatedCircle>
                  <Routes>
                      <Route path="/" element={<MainPage />} >
                          <Route index element={<MainBody />} />
                          <Route path="discovery" element={<DiscoveryWrapper/>}>
                              <Route index element={<DiscoveryBody/>}/>
                              <Route index path="restaurants" element={<Restaurant/>}/>
                              <Route index path="stores" element={<Stores/>}/>
                              <Route index path="search-store" element={<SearchPage/>}/>
                              <Route path="me" element={userData.authorized ? <PersonalInfo/> : <LoginRegister/>}>
                                  <Route path="personal-info" element={<PersonalInfoTab/>} />
                                  <Route path="payments" element={<PaymentMethods />} />
                                  <Route path="addresses" element={<AddresTab />} />
                                  <Route path="order-history" element={<OrderHistory />} />
                                  <Route path="order-chat" element={<ChatCurier />} />
                                  <Route path="order-history/:id" element={<OrderCard />} />
                                  <Route path="earn-credits" element={<EarnWoltCreaditsTab />} />
                                  <Route path="balance" element={<TransactionsPage />} />
                                  <Route path="balance/:id" element={<TransactionMainCard />} />
                                  <Route path="balance/up" element={<BalancePage />} />
                                  <Route path="redeem-code" element={<RedeemCodeTab />} />
                                  <Route path="settings" element={<SettingsTab />} />
                                  <Route path="my-addreses" element={<AddresPage />} />
                                  <Route path="credits-tokens" element={<CreditsTokens />} />
                                  <Route path="account-mobile" element={<AccountMobile />} />
                                  <Route path="verification" element={<VerificationPage />} />
                                  <Route path="verification2" element={<VerificationPage2 />} />
                                  <Route path="verification3" element={<VerificationPage3 />} />
                                  <Route path="change-mobile" element={<ChangeMobile />} />
                              </Route>
                          </Route>
                          <Route path="/restoran" element={<RestoranBody/>}/>
                          <Route path="/stores/:id" element={<StoresBody/>}/>
                          <Route path="/checkout" element={<CheckoutBody/>}/>
                          <Route path="/moderator" element={<ModeratorPanel/>}/>
                          <Route path="moderator/order/:id" element={<OrderCardMain />}/>
                          <Route path="/curier-panel" element={<CurierWrapper/>}>
                              <Route path="actual" element={<ActualHistory/>}/>
                              <Route path="actual/:id" element={<OrderCardActual/>}/>
                              <Route path="worked" element={<WorkedHistory/>}/>
                              <Route path="worked/:id" element={<OrderCardWorked />}/>
                              <Route path="worked/:id/info" element={<InfoOrderCardWorked />}/>
                              {/*AllHistory по пути all заменен на CourierFinances*/}
                              <Route path="all" element={<CourierFinances />}/>
                              {/* OrderCardMainOld по пути all/:id заменен на CourierDeliveries*/}
                              <Route path="all/:date" element={<CourierDeliveries />}/>
                          </Route>
                          <Route path="/checkout-mobile" element={<CheckoutMobile/>}/>
                          <Route path="*" element={<NotFount/>}/>
                      </Route>
                  </Routes>
                  </AnimatedCircle>
              </BrowserRouter>
          </ThemeProvider>
      </Provider>
  );
}
export default observer(App);
