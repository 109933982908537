import React from 'react';
import styles from './StatisticShopTab.module.scss'
import {observer} from "mobx-react";
import rootStore from "../../../store/RootStore";
import MENU from "../../../constants/constantMenu";
import {t} from "i18next";


const StatisticShopTab = ({shop, index}) => {
    const { setCurrentShopId } = rootStore.statisticStore;
    const { setActivePanel } = rootStore.moderatorPanelStore;

    const { getStatisticDataCurrent } = rootStore.statisticStore;

    const handleClickCurrentShop = () => {
        setCurrentShopId(shop.ShopID)
        getStatisticDataCurrent(shop.shop_id, shop.date)
        setActivePanel(MENU.StatisticsCurrent);
    }

    return (
        <div className={styles.mainConteiner} onClick={() => handleClickCurrentShop()}>
            <div className={styles.topProfitBlock}>
                <div className={styles.shiftBlock}>
                    <span>{t("Shift")} №{++index} </span>
                </div>
                <span className={styles.shopName}>{shop.date}</span>
            </div>
            <div className={styles.downProfitBlock}>
                <div className={styles.profitBlock}>
                    <span className={styles.profitTitle}>{t("Cash")}</span>
                    <span className={styles.profitCount}>{shop.total_revenue} $</span>
                </div>
            </div>
        </div>
    );
};

export default observer(StatisticShopTab);