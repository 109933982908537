import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import rootStore from "../../store/RootStore";
import {CircularProgress} from "@mui/material";
import {useEffect} from "react";
import useIsMobile from "../../helpers/useIsMobile";
import TabPanel from "../TabPanel/TabPanel";



function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ModeratorShopsList = ({setShopData}) => {
    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });
    const shops = rootStore.superUserStore.superUserShopsList;
    const setCurrentShopId = rootStore.superUserStore.setCurrentShopId;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const isMobile = useIsMobile();
    useEffect(() => {
        if(!localStorage.getItem('selectedTab') && shops.length) {
            localStorage.setItem('selectedTab', "0");
            localStorage.setItem('currentShop', JSON.stringify(shops[0]));
        }
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        setShopData(shops[newValue]);
    };

    if (!shops || !shops.length) {
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh"}}>
                <CircularProgress/>
            </Box>
        )
    }

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider',minWidth: isMobile ? 110 : 300 }}
            >
                {shops.map((shop, index) => (
                    <Tab  label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID} />
                ))}
            </Tabs>
            {shops.map((shop, index) => (
                <TabPanel value={value} key={shop.ShopID} index={index} data={shop} />
            ))}
        </Box>
    );

};

export default ModeratorShopsList;