import React, { useEffect } from 'react';

import { t } from "i18next";
import { observer } from "mobx-react";

import styles from "./ActualHistory.module.scss"

import rootStore from "../../../store/RootStore";
import HistoryCart from "../../../components/HistoryCart/HistoryCart";
import DiscoveryParent from "../../../components/DiscoveryParent/DiscoveryParent";

const ActualHistory =() => {
    const {getActualOrders, loadActualOrders} = rootStore.curierStore;
    // TODO: Добавить запрос новых orders и вливать их в текущие на стороне фронта.
    useEffect(() => {
        loadActualOrders();
    }, []);

    const arr = [...getActualOrders];
    const sortedArr = arr.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return (
        <DiscoveryParent>
            <div className={styles.mainBlock}>
                <h1 className={styles.titleMenu}>{t("Actual orders")}</h1>
                <div className={styles.lvl1}>
                    {
                        sortedArr?.length &&  sortedArr.map((el, index) => (
                            <HistoryCart data={el} index={index} key={el.id}/>
                        ))
                    }
                </div>
            </div>
        </DiscoveryParent>
    );
};

export default observer(ActualHistory);