import React, { useEffect, useState, useMemo } from 'react';

import { observer } from "mobx-react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import styles from './StatisticPage.module.scss'

import rootStore from "../../store/RootStore";
import StatisticShopTab from "./StatisticShopTab/StatisticShopTab";
import { sortStatisticsDataByDate } from "./helpers";
import { toJS } from "mobx";

const StatisticPage = () => {
    const shops = rootStore.superUserStore.superUserShopsList;
    const setCurrentShopId = rootStore.superUserStore.setCurrentShopId;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const { getStatisticDataAllF, getStatisticsDataAll } = rootStore.statisticStore;

    const [openInfo, setOpenInfo] = useState(false);
    const [curierName, setCurierName] = useState();

    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });

    useEffect(() => {
        getStatisticDataAllF();
        if (!localStorage.getItem("currentShop")) {
            handleChange('test', 0)
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        // setShopData(shops[newValue]);
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const handleOpenInfo = (name) => {
        setCurierName(name)
        setOpenInfo(true)
    }
    
    const sortedStatisticsData = useMemo(() => {
        return sortStatisticsDataByDate(toJS(getStatisticsDataAll));
    }, [getStatisticsDataAll]);

    return (
        <div className={styles.mainConteiner}>
            <Box
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'var(--background-color)',
                    display: 'flex',
                    flexDirection: true ? "column" : "row",
                    height: "100%"

                }}
            >
                <Tabs
                    orientation={true ? "horizontal" : "vertical"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', minWidth: true ? 256 : 300}}
                >
                    {shops.map((shop, index) => (
                        <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>
                    ))}
                </Tabs>
                <div className={styles.contentBlock}>
                    {
                        sortedStatisticsData.filter(shop => shop.shop_id === shops[value].ShopID).map((shop, index) => (
                            <StatisticShopTab shop={shop} index={index} key={index}/>
                        ))
                    }
                </div>
            </Box>
        </div>
    );
};

export default observer(StatisticPage);
