import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

import styles from "./OrderAgine.module.scss";

import { getCord } from "../../../helpers";
import rootStore from "../../../store/RootStore";
import CircleBlock from "../CircleBlock/CircleBlock";
import { useTheme } from "../../../helpers/ThemeContext";
import { CheckIcon, UserChatIcon } from "../../../assets/icons";

const OrderAgine = ({ closeDrawer, isCheckout }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [deliveryTimeInSeconds, setDeliveryTimeInSeconds] = useState(0);

  const { loadUserTargetOrder } = rootStore.curierStore;
  const { getLoader, setLoader } = rootStore.shopStore;
  const { setOpenOrderAgine, getTargetOrderCheck: targetOrderCheck } =
    rootStore.cartOrderStore;

  const placemark = getCord()
    ? [JSON.parse(getCord())?.[0], JSON.parse(getCord())?.[1]]
    : [32.05, 34.85];

  const defState = getCord()
    ? [JSON.parse(getCord())?.[0] - 0.025, JSON.parse(getCord())?.[1]]
    : [32.025, 34.85];

  const navigate = useNavigate();
  const { themeMode } = useTheme();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCloseMap = () => {
    if (isCheckout) {
      navigate("/discovery");
    }
    setOpenOrderAgine(false);
  };

  const goToCheck = () => {
    loadUserTargetOrder(targetOrderCheck.id);
    setTimeout(() => {
      navigate(`/discovery/me/order-history/${targetOrderCheck.id}`);
    }, 250);
  };

  const goToChat = () => {
    setTimeout(() => {
      navigate(`/discovery/me/order-chat`);
    }, 250);
  };

  useEffect(() => {
    targetOrderCheck ? setLoader(false) : setLoader(true);
  }, [targetOrderCheck]);

  useEffect(() => {
    const time = new Date(
      new Date(targetOrderCheck?.created_at).getTime() +
        60000 * targetOrderCheck?.small_order_surcharge_limit?.split(".")[0]
    );
    setDeliveryTime(time);
    const deliveryTimeMs = new Date(time).getTime();
    const actualTimeMs = new Date().getTime();

    setDeliveryTimeInSeconds(
      Math.max(Number((deliveryTimeMs - actualTimeMs) / 1000).toFixed(0), 0)
    );
  }, [targetOrderCheck]);

  const formattedHours = deliveryTime?.getHours().toString().padStart(2, "0");
  const formattedMinutes = deliveryTime
    ?.getMinutes()
    .toString()
    .padStart(2, "0");

  const handleLoadingMap = () => {
    setMapLoaded(true);
  };
  return (
    <>
      {getLoader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          className={styles.basket}
          sx={{
            position: "absolute",
            width: windowWidth > 830 ? "550px" : "100%",
            height: windowWidth > 830 ? "100%" : "100vh",
            zIndex: 99999999,
          }}
          role="presentation"
        >
          <>
            <button
              className={styles.mainStyle}
              onClick={() => handleCloseMap()}
            >
              X
            </button>
            {!mapLoaded && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  zIndex: 1000,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <div
              className={styles.back}
              style={{ hidden: mapLoaded ? true : false }}
            >
              <YMaps query={{ lang: "en_EU" }} style={{ zIndex: 50 }}>
                <div className={styles.mapBlock}>
                  <Map
                    onLoad={handleLoadingMap}
                    defaultState={{ center: defState, zoom: 13 }}
                    state={{ center: defState, zoom: 13 }}
                    width={"100%"}
                    height={"100vh"}
                  >
                    {placemark && <Placemark geometry={placemark} />}
                  </Map>
                  <div className={styles.blockedBlock}></div>
                </div>
              </YMaps>
            </div>
            <div
              className={
                themeMode === "dark"
                  ? styles["blockContent__dark"]
                  : styles.blockContent
              }
            >
              <CircleBlock
                targetOrder={targetOrderCheck}
                deliveryTime={deliveryTimeInSeconds}
              />
              <div className={styles.buttonsPanel}>
                <button
                  className={styles.backGraoundBtn}
                  onClick={() => goToCheck()}
                >
                  <CheckIcon />
                </button>
                <button
                  className={styles.backGraoundBtn}
                  onClick={() => goToChat()}
                >
                  <UserChatIcon />
                </button>
              </div>
              <div className={styles.conteinerForText}>
                <div className={styles.text}>
                  <span>{t("Your order prepared right now")}</span>
                  <span>{t("Estimated time of receipt:")}</span>
                  <span
                    className={styles.timer}
                  >{`${formattedHours}:${formattedMinutes}`}</span>
                </div>
              </div>
            </div>
          </>
        </Box>
      )}
    </>
  );
};

export default observer(OrderAgine);
