import React, {useEffect, useRef} from 'react';
import styles from "./ModeratorBody.module.scss"
import {observer} from "mobx-react";

import ModeratorBodyMenu from "./ModeratorBodyMenu/ModeratorBodyMenu";
import ModeratorShopsList from "../ModeratorShops/ModeratorShopsList";
import ModeratorSetting from "../ModeratorSettings/ModeratorSetting";
import rootStore from "../../store/RootStore";
import MENU from "../../constants/constantMenu";
import CurierSettingPanel from "../CurierSettingPanel/CurierSettingPanel";
import StatisticPage from "../../pages/StatisticPage/StatisticPage";
import CurrentShopPage from "../../pages/StatisticPage/CurrentShopPage/CurrentShopPage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useIsMobile from "../../helpers/useIsMobile";
import OrdersConfirmPanel from "../OrdersConfirmPanel/OrdersConfirmPanel";
import ModeratorSubscribe from "../ModeratorSubscribe/ModeratorSubscribe";
import {t} from "i18next";

const ModeratorBody = observer(() => {
    const { getActivePanel, setActivePanel,getIsOpenSettingShop} = rootStore.moderatorPanelStore;
    const setCurrentShopId = rootStore.superUserStore.setCurrentShopId;
    const setCurrentShopData = rootStore.superUserStore.setCurrentData;
    const {getSearchText, setSearchText} = rootStore.searchStore;
    const shops = rootStore.superUserStore.superUserShopsList;
    const [shopData, setShopData] = React.useState(null);
    const isMobile = useIsMobile();
    const [value, setValue] = React.useState(() => {
        const storedValue = localStorage.getItem('selectedTab');
        return storedValue ? parseInt(storedValue) : 0;
    });
    const notFoundShop = useRef(null)
    useEffect(() => {
        const storedValue = localStorage.getItem('currentShop');
        if (!storedValue) {
            notFoundShop.current = true
            setActivePanel(MENU.Setting)
        }
        if (storedValue) {
            setShopData(JSON.parse(storedValue));
        }
        else{
            if(getActivePanel !== MENU.Curiers){
                setActivePanel(MENU.Menu)
            }
        }
        if(getIsOpenSettingShop && getActivePanel !== MENU.Subscribe){
            setActivePanel(MENU.Setting)
        }

    },[getActivePanel])


    const handleChangePanel = (menuElem) => {
        if (shops.length) {
            setSearchText("")
            setActivePanel(menuElem)
        }
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
        localStorage.setItem('currentShop', JSON.stringify(shops[newValue]));
        setCurrentShopId(shops[newValue]?.ShopID);
        setCurrentShopData(shops[newValue]);
        setShopData(shops[newValue]);
    };


    return (
        <div className={styles.contentMain}>
            <div className={`${styles.menuPanelBar} ${getActivePanel === MENU.StatisticsCurrent && styles.kill}`}>
                <div className={styles.menuPanelBlock}>
                    {!getIsOpenSettingShop && (getActivePanel !== MENU.Curiers) && (getActivePanel !== MENU.Orders) && getActivePanel !== MENU.Statistics &&
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{borderRight: 1, borderColor: 'divider', minWidth: isMobile ? 256 : 300}}
                        >
                            {shops.map((shop, index) => (
                                <Tab label={shop.ShopName} {...a11yProps(shop.ShopID)} key={shop.ShopID}/>
                            ))}
                        </Tabs>
                    }
                    {!getIsOpenSettingShop && (getActivePanel !== MENU.Curiers) && (getActivePanel !== MENU.Orders) && getActivePanel !== MENU.Statistics && getActivePanel !== MENU.Menu && <div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Shops ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Shops)}>{t("Shops")}{`${shopData ? " ("+shopData.ShopName+" )" : ""}`}</div>}
                    {getIsOpenSettingShop && (getActivePanel !== MENU.Curiers) && (getActivePanel !== MENU.Orders) && getActivePanel !== MENU.Statistics  && <div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Setting ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Setting)}>{t("Setting")}</div>}
                    {getIsOpenSettingShop && (getActivePanel !== MENU.Curiers) && (getActivePanel !== MENU.Orders) && getActivePanel !== MENU.Statistics  && <div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Subscribe ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Subscribe)}>{t("Subscribe")}</div>}
                    {getActivePanel === MENU.Curiers &&  (<div className={styles.curierBlock} onClick={() => handleChangePanel(MENU.Curiers)}>{t("Courier Setting")}</div>)}
                    {getActivePanel === MENU.Orders &&  (<div className={styles.ordersBlock} onClick={() => handleChangePanel(MENU.Orders)}>{t("Orders for confirmation")}</div>)}
                    {/*<div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Ingredients ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Ingredients)}>Ингредиенты</div>
                    {/*<div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Modifiers ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Modifiers)}>Модификаторы</div>*/}
                    {/*<div className={`${styles.menuPanelItem} ${getActivePanel === MENU.Remains ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.Remains)}>Остатки</div>*/}
                    {/*<div className={`${styles.menuPanelItem} ${getActivePanel === MENU.ProductMovement ? styles.activePanel : ""}`} onClick={() => handleChangePanel(MENU.ProductMovement)}>Движение товара</div>*/}
                </div>
            </div>
            {
                getActivePanel === MENU.Statistics && <StatisticPage/>
            }
            {
                getActivePanel === MENU.Menu && <ModeratorBodyMenu />
            }
            {
                getActivePanel === MENU.StatisticsCurrent && <CurrentShopPage />
            }
            {
                getActivePanel === MENU.Shops && <ModeratorShopsList setShopData={setShopData} />
            }
            {
                getActivePanel === MENU.Curiers && <CurierSettingPanel/>
            }
            {
                getActivePanel === MENU.Orders && <OrdersConfirmPanel/>
            }
            {
                getActivePanel === MENU.Setting && <ModeratorSetting setShopData={setShopData} />
            }
            {
                getActivePanel === MENU.Subscribe && <ModeratorSubscribe />
            }
        </div>
    );
});

export default ModeratorBody;