import React from 'react';
import styles from'./IconBack.module.scss';
import {useTheme} from "../../helpers/ThemeContext";
import {useNavigate} from "react-router-dom";

const IconBack = ({size="24px",pure= false, fill="black", marg="5px",disabled = false}) => {
    const {themeMode} = useTheme()
    const top = window.location.pathname === '/checkout-mobile' ? "3px" : 0;
    const navigate = useNavigate();
    const moderator = window.location.pathname === '/moderator'
    if(moderator) {
        return null
    }
    const handleClick = () => {
        if(disabled) return
        navigate(-1)
    }
    return (
        <button onClick={handleClick} className={`${pure?"":styles.IconBack} ${styles.mainStyle}`} style={{
            marginTop: marg,
            zIndex: 999999,
            backgroundColor:  "var(--dark-grey-color)",
        }} >
            <div className={styles["IconBack__icon"]}>
                <svg viewBox="0 0 24 24"
                     width={size}
                     height={size}
                     color={"black"}
                     style={{
                         marginTop: top
                     }}
                     className={styles.mainSvg}>
                    <path className={styles.mainSvg}
                        fill={fill}
                        d="M22.533 10.526H5.422a.251.251 0 01-.165-.438l4.637-3.6a1.44 1.44 0 00-1.9-2.162L.813 10.165a2.4 2.4 0 000 3.6l7.179 5.837a1.44 1.44 0 001.9-2.161l-4.637-3.6a.251.251 0 01.165-.438h17.113a1.44 1.44 0 000-2.88v.003z"
                    ></path>
                </svg>
            </div>
        </button>
    );
};

export default IconBack;
