import React, {useEffect, useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import styles from './CreateCategoryModal.module.scss';
// import {addCategory} from "../../../../../api/addContent/postCategories";
// import {getSuperUserShopCategoriesList} from "../../../../../api/getContentsApi/getSuperUserShopCategoriesList";
import rootStore from "../../../../store/RootStore";
import {getSuperUserShopCategoriesList} from "../../../../api/getContentsApi/getSuperUserShopCategoriesList";
import {addCategory} from "../../../../api/addContent/postCategories";
import IconImage from "../../../Icons/IconImage";
import {t} from "i18next";

const defaultCategoryList =
    [{
        CategoryName: "ALL",
        CategoryID:0,
    },]
const CreateCategoryModal = ({setState}) => {
    const { setIfOpenCreateCatalog } = rootStore.moderatorPanelStore;
    const [categoryListData, setCategoryListData] = useState([]);
    const storedShopValue = localStorage.getItem("currentShop");
    let shopId = rootStore.superUserStore.currentShopId || JSON.parse(storedShopValue)?.ShopID;

    useEffect(() => {
        const fetchSuperUserShopCategoriesList = async () => {
            if (shopId) {
                try {
                    const data = await getSuperUserShopCategoriesList(shopId);
                    if (data?.length) {
                        setCategoryListData(defaultCategoryList.concat(data))
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }
        }
        fetchSuperUserShopCategoriesList();
        return () => setCategoryListData([]);
    }, [shopId]);

    const [isFocused, setIsFocused] = useState(false);

    const [shopData, setShopData] = useState(null);
    useEffect(() => {
        const storedValue = localStorage.getItem('currentShop');
        if (storedValue) {
            setShopData(JSON.parse(storedValue));
        }
        return () => setShopData(null);
    },[]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleClose = () => {
        setIfOpenCreateCatalog(false);
    };

    const onSubmit = async (values) => {
        const jsonData = {
            CategoryName: values.categoryName,
            ParentCategoryID: values.parentCategory ?  Number(values.parentCategory): null,
            image: values.image,
            AltText: values.image.name
        };
        await addCategory (jsonData,shopData?.ShopID);
        rootStore.categoriesStore.updateCategories();
        setState(prev => !prev)
        handleClose();
    };
    const initialValues = {
        categoryName: '',
        parentCategory: null,
        image: null,
    };

    const validationSchema = Yup.object().shape({
        categoryName: Yup.string().required(t("Required field category name")),
        image: Yup.mixed()
            .required(t("Please select a image"))
            .test('fileSize', t("File size is too large, maximum 10MB allowed"), (value) => {
                if (value) {
                    return value.size <= 10485760;
                }
                return true;
            })
            .test('fileType', t("File must be an image"), (value) => {
                if (value) {
                    return value && value.type.startsWith('image/');
                }
                return true;
            }),
    });

    const handleFileChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        setFieldValue('image', file);
    };


    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalContainerBlock}>
                <div className={styles.productsCategoryModal}>
                    <div className={styles.productsCategoryTextBlock}>
                        <span>{t("Create category products")}</span>
                        <span className={styles.productsCategoryTextImg} onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path fill="var(--text-color)" fill-rule="nonzero" d="M5.30963553,18.6903645 C4.8990815,18.2798104 4.90303703,17.6102153 5.30742781,17.2058245 L10.5132523,12 L5.30742781,6.79417547 C4.89809307,6.38484073 4.89621919,5.72305186 5.30963553,5.30963553 C5.72018956,4.8990815 6.38978469,4.90303703 6.79417547,5.30742781 L12,10.5132523 L17.2058245,5.30742781 C17.6151593,4.89809307 18.2769481,4.89621919 18.6903645,5.30963553 C19.1009185,5.72018956 19.096963,6.38978469 18.6925722,6.79417547 L13.4867477,12 L18.6925722,17.2058245 C19.1019069,17.6151593 19.1037808,18.2769481 18.6903645,18.6903645 C18.2798104,19.1009185 17.6102153,19.096963 17.2058245,18.6925722 L12,13.4867477 L6.79417547,18.6925722 C6.38484073,19.1019069 5.72305186,19.1037808 5.30963553,18.6903645 Z"></path>
              </svg>
            </span>
                    </div>
                    {!shopData? <div className={styles.mainBlock}>{t("Shop not found, please check shop!")}</div>:
                    <div className={styles.mainBlock}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ setFieldValue }) => (
                                <Form className={styles.form}>
                                    <div className={styles.mainConteiner}>
                                        <div
                                            className={isFocused ? `${styles.mainBlockOne} ${styles.mainBlockOne__focus}` : styles.mainBlockOne}>
                                            <label htmlFor="categoryName" className={styles.mainBlockLabel}>
                                                {t("Category name")}
                                            </label>
                                            <Field
                                                type="text"
                                                id="categoryName"
                                                name="categoryName"
                                                className={styles.mainBlockDownInput}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="categoryName" component="div" className={styles.error}/>
                                        </div>

                                        <div className={styles.mainBlockTwo}>
                                        <label htmlFor="parentCategory" className={styles.mainBlockLabel}>
                                            {t("Parent category")}
                                            </label>
                                            <Field
                                                type="text"
                                                id="parentCategory"
                                                name="parentCategory"
                                                as="select"
                                                className={styles.mainBlockDownInput}
                                            >
                                                {categoryListData.map((category) => (
                                                    <option key={category.CategoryID} value={category.CategoryID}>{category.CategoryName}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="parentCategory" component="div" className={styles.error}/>
                                        </div>
                                        <div className={styles["mainBlockDown__image"]}>
                                            <label htmlFor="image" className={styles.mainBlockLabel}>
                                                {t("Image for category")}
                                            </label>
                                            <IconImage/>
                                            <input
                                                type="file"
                                                id="image"
                                                name="image"
                                                className={styles.mainBlockDownInput}
                                                onChange={(e) => handleFileChange(e, setFieldValue)}
                                            />
                                            <ErrorMessage name="image" component="div" className={styles.error} />
                                        </div>
                                        <div className={styles.btnBlock}>
                                            <button type="submit" className={styles.btnMain}>
                                                {t("Submit")}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default observer(CreateCategoryModal);
